.historyWrapper {
  max-width: 1180px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.historyBtn {
  margin-bottom: 30px;
  width: 100%;
  max-width: 300px;
  padding: 10px 0;
  border: none;
  border-radius: 10px;
  background-color: var(--purple);
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  color: var(--white);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.historyBtn {
  width: 260px;
  height: 50px;
  align-self: flex-end;
  background: var(--main-gradient);
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: var(--white);
  border: none;
  border-radius: 10px;
}

.historyBtn:hover {
  background: var(--main-gradient-hover);
}

.historyBtn:focus {
  background: var(--main-gradient-focus);
}

.historyTitle {
  margin-bottom: 14px;
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 29px;
  color: var(--white);
}

.historyUserName {
  margin-bottom: 30px;
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 700;
  font-size: 29px;
  line-height: 29px;
  text-transform: capitalize;
  color: var(--inactive-input-border);
}

.historyTableBackground {
  max-height: 467px;
  padding: 21px 20px;
  background-color: var(--app-light-purple);
  border-radius: 10px;
}

.historyTableWrapper {
  max-height: 430px;
  overflow-x: hidden;
  overflow-y: auto;
}

.historyTableWrapper::-webkit-scrollbar {
  width: 3px;
}

.historyTableWrapper::-webkit-scrollbar-track {
  background: transparent;
}

.historyTableWrapper::-webkit-scrollbar-thumb {
  background: var(--inactive-input-border);
  border-radius: 1px;
}

.historyTableWrapper::-webkit-scrollbar-thumb:hover {
  background: var(--active-tab);
}

.historyTableHeading {
    padding: 0 80px 17px 0;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: var(--inactive-input-border);
}

.historyTableHeading:not(:first-child) {
  padding-left: 19px;
  padding-right: 120px;
}

.historyTableHeading:last-child {
  padding-right: 70px;
}

.historyTableCell {
  position: relative;
  padding: 10px 15px 9px 0;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: var(--white);
}

.historyTableCellAcc {
  width: 90px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.historyTableCellAcc .historyTableCelltooltiptext {
  position: absolute;
  padding: 6px;
  border-radius: 7px;
  top: 0;
  left: -2px;
  z-index: 1;
  background-color: var(--purple);
  visibility: hidden;
}

.historyTableCellAcc:hover .historyTableCelltooltiptext {
  visibility: visible;
}

.expenses {
  color: var(--pink);
}

.historyTableCell:nth-child(2) {
  padding-left: 20px;
}

.historyTableCell:nth-child(3) {
  width: fit-content;
  padding-left: 12px;
}

.historyTableThead {
  border-bottom: 1px solid var(--purple);
}

.historyTable {
  width: 100%;
  border: none;
  border-collapse: collapse;
}

.historyTableHeading,
.historyTableCell {
  border-left: 1px solid var(--purple);
  border-right: 1px solid var(--purple);
}

.historyTableHeading:first-child,
.historyTableCell:first-child {
  border-left: none;
}

.historyTableHeading:last-child,
.historyTableCell:last-child {
  border-right: none;
}

@media(max-width: 765px) {
  .historyBtn {
    align-self: center;
  }
}

@media(max-width: 550px) {
  .historyTitle {
    font-size: 25px;
  }

  .historyTableBackground {
    padding: 18px 5px
  }

  .historyTableCell {
    padding: 5px 5px 5px 0;
    font-size: 13px;
  }

  .historyTableCell:nth-child(2) {
    padding-left: 8px;
  }
    
  .historyTableHeading:not(:first-child) {
    padding: 0 10px 15px 10px;
  }

  .historyTableHeading:nth-child(2) {
    padding-right: 75px;
  }

  .historyTableHeading:first-child {
    padding-right: 40px;
  }

  .historyTableHeading {
    padding: 0 88px 15px 0;
  }
}

@media(max-width: 480px) {
  .historyTitle {
    font-size: 19px;
  }
}

@media(max-width: 390px) {
  .historyWrapper {
    padding: 20px 0;
  }
}

@media(max-width: 345px) {
  .historyTitle {
    font-size: 19px;
  }

  .historyUserName {
    font-size: 26px;
  }
}

