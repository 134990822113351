.linksList {
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  margin-right: 4.85vw;
}

.linksItem:not(:last-child) {
  margin-bottom: 14px;
}

.linksBtn {
  background-color: transparent;
  border: none;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  color: var(--white);
}

.linksBtn:hover {
  color: var(--pink);
}

@media (min-width: 1440px) {
  .linksList {
    margin-right: 70px;
  }
}

@media (max-width: 1024px) {
  .linksList {
    margin-right: 0;
    white-space: nowrap;
  }
}

@media (max-width: 640px) {
  .linksList {
    width: 253px;
    margin-right: 0;
    white-space: nowrap;
  }
}

@media (max-width: 320px) {
  .linksItem:not(:last-child) {
    margin-bottom: 2px;
  }

  .linksBtn {
    font-size: 14px;
    line-height: 130%;
  }
}
