.contentWrapper {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.heading {
  margin-bottom: 20px;
  color: var(--white);
  font-size: 35px;
  font-weight: 700;
  text-align: center;
}

.wallet {
  margin-bottom: 35px;
  color: var(--swiper-theme-color);
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
}

.wallet:hover {
  color: var(--pink);
  text-decoration: underline;
}

.subtitle {
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 15px;
  color: chartreuse;
}

.paymentAgreementWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
}

.paymentAgreementBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 241px;
  height: 55px;
  background: var(--main-gradient);
  border: none;
  border-radius: 8px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: var(--white);
}

.paymentAgreementBtn:hover {
  cursor: url('../../../../../../../assets/svg/customHoverCursor.svg'), pointer;
  background: var(--button-hover-gradient);
}

.paymentAgreementBtn:focus {
  background: var(--button-focus-gradient);
}

.paymentAgreementBtn:active {
  background: var(--blue);
}

@media (max-width: 1200px) {
  .paymentAgreementWrapper {
    padding: 0;
  }

  .heading {
    font-size: 33px;
  }
  
  .wallet {
    font-size: 26px;
  }
}

@media (max-width: 768px) {
  .contentWrapper {
    margin: 0;
    gap: 25px;
  }
  
  .heading {
    margin-bottom: 15px;
    font-size: 24px;
  }

  .subtitle {
    font-size: 25px;
  }

  .wallet {
    margin-bottom: 25px;
    font-size: 19px;
  }

  .paymentAgreementWrapper {
    padding: 0;
  }

  .paymentAgreementBtn {
    width: 190px;
    height: 50px;
    font-size: 16px;
  }
}