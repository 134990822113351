.paymentSection {
  max-width: 1180px;
  margin: 0 auto;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
}

.paymentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.paymentHeadingWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 50px;
}

.paymentSectionTitle {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 45px;
  color: var(--white);
  grid-column-start: 2;
  grid-column-end: 3;
  white-space: nowrap;
}

.paymentContentWrapper {
  width: 950px;
  display: flex;
  flex-direction: column;
  padding: 40px;
  background: var(--app-light-purple);
  border-radius: 10px;
}

.backBtn {
  justify-self: flex-end;
  grid-column-start: 3;
  grid-column-end: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 241px;
  height: 55px;
  background: var(--main-gradient);
  border: none;
  border-radius: 8px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: var(--white);
}

.backBtn:hover {
  cursor: url('../../../../../assets/svg/customHoverCursor.svg'), pointer;
  background: var(--button-hover-gradient);
}

.backBtn:focus {
  background: var(--button-focus-gradient);
}

.backBtn:active {
  background: var(--blue);
}

@media (max-width: 1200px) {
  .paymentHeadingWrapper {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 20px;
  }

  .paymentContentWrapper {
    width: 768px;
  }
}

@media (max-width: 768px) {
  .paymentContentWrapper {
    width: 500px;
    padding: 30px;
  }
}
