.reviewsSection {
  max-width: 1280px;
  margin: 0 auto;
  padding: 90px 9px 119px 50px;
}

.reviewsTitle {
  margin-bottom: 40px;
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 45px;
  color: var(--purple);
}

.swiperWrapper {
  position: relative;
  padding-left: 111px;
  padding-right: 122px;
}

.reviewsFigure {
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reviewsAvatar {
  width: 140px;
  height: 140px;
  margin-bottom: 10px;
}

.reviewsName {
  margin-bottom: 9px;
  font-family: 'Nunito';
  font-weight: 700;
  font-size: 18px;
  line-height: 130%;
  color: var(--purple);
}

.reviewsBlockqoute {
  max-width: 250px;
  text-align: center;
  font-family: 'Nunito';
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  color: var(--purple);
}

.reviewsBtnPrev,
.reviewsBtnNext {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 33.8%;
  border: none;
  background: transparent;
  border-radius: 30px;
  z-index: 1;
}

.reviewsBtnPrev:hover,
.reviewsBtnNext:hover,
.reviewsBtnPrev:focus,
.reviewsBtnNext:focus {
  background: var(--light-grey);
}

.reviewsBtnPrev {
  left: 4.1%;
}

.reviewsBtnNext {
  right: 7.5%;
}

@media (max-width: 1024px) {
  .reviewsSection {
    padding: 90px 9px 96px 22px;
  }

  .swiperWrapper {
    padding-left: 70px;
    padding-right: 83px;
  }

  .reviewsBtnPrev {
    left: 0;
  }

  .reviewsBtnNext {
    right: 1.3%;
  }
}

@media (max-width: 768px) {
  .reviewsSection {
    padding: 80px 9px 79px 94px;
  }

  .reviewsTitle {
    max-width: 380px;
  }

  .swiperWrapper {
    padding-left: 72px;
    padding-right: 104px;
  }

  .reviewsBtnPrev {
    top: 30.5%;
  }

  .reviewsBtnNext {
    top: 30.5%;
    right: 12.8%;
  }

  .reviewsFigure {
    max-width: 220px;
  }

  .reviewsAvatar {
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 550px) {
  .reviewsSection {
    padding: 80px 9px 79px 16vw;
  }

  .swiperWrapper {
    padding-left: 0;
    padding-right: 11vw;
  }

  .reviewsSwiperSlide {
    display: flex;
    justify-content: center;
  }

  .reviewsBtnPrev {
    top: 30.5%;
  }

  .reviewsBtnNext {
    top: 30.5%;
    right: 12.8%;
  }

  .reviewsFigure {
    max-width: 220px;
  }

  .reviewsAvatar {
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 320px) {
  .reviewsSection {
    padding: 60px 0 71px 15px;
  }

  .reviewsTitle {
    max-width: 210px;
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 20px;
  }

  .swiperWrapper {
    padding-left: 48px;
    padding-right: 0;
  }

  .reviewsAvatar {
    margin-bottom: 11px;
  }

  .reviewsName {
    font-size: 14px;
  }

  .reviewsSwiperSlide {
    display: flex;
    justify-content: flex-start;
  }

  .reviewsBlockqoute {
    max-width: 191px;
    font-size: 14px;
  }

  .reviewsArrowImg {
    width: 36px;
    height: 36px;
  }

  .reviewsBtnPrev {
    top: 31%;
  }

  .reviewsBtnNext {
    top: 31%;
    right: 4.8%;
  }

  .reviewsFigure {
    max-width: 191px;
  }
}
