.navigation {
  display: flex;
  align-items: center;
}

.navItemButton {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: var(--white);
  background-color: transparent;
  border: none;
}

.navItemButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.exitText {
  margin-right: 5px;
}

.navItemButton:hover {
  color: var(--pink);
}

.navItemButton:hover .navItemExitImg path {
  fill: var(--pink);
}
