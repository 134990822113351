.navigate {
  display: flex;
  align-items: center;
}

.list {
  display: flex;
}

.item {
  color: var(--white);
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
}

.item:not(:last-child) {
  margin-right: 40px;
}

.link {
  cursor: pointer;
}

.link:hover {
  color: var(--pink);
}
