.registrationSection {
  width: 100%;
  padding: 70px 0 90px 0;
  background: var(--registration-background);
}

.registrationWrapper {
  max-width: 1280px;
  margin: 0 auto;
}

.registrationTitle {
  padding-left: 50px;
  max-width: 680px;
  margin-bottom: 31px;
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 45px;
}

.registrationText {
  padding-left: 50px;
  margin-bottom: 59px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 120%;
}

.registrationFormUnderlay {
  padding-left: 50px;
}

.registrationFormWrapper {
  max-width: 1180px;
}

.registrationForm {
  display: flex;
  flex-direction: column;
}

.registrationTabsList {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-bottom: 40px;
}

.registrationTabsItem {
  width: 50px;
  height: 50px;
  color: var(--active-tab);
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 41px;
  text-align: center;
}

.registrationTabsBtn {
  border: none;
  background-color: transparent;
}

.registrationInputList {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 11px;
}

.registrationInputItem {
  height: 114px;
  display: flex;
  flex-direction: column;
}
.registrationInputItem:last-child {
  justify-content: flex-end;
}

.registrationLabel {
  margin-bottom: 2px;
  padding-left: 20px;
  color: var(--light-purple);
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
}

.registrationInput {
  width: 380px;
  height: 70px;
  padding-left: 18px;
  background: var(--white);
  font-size: 27px;
  border: 2px solid var(--inactive-input-border);
  border-radius: 10px;
  caret-color: var(--purple);
}

.registrationInput:focus {
  outline: none;
  border: 2px solid var(--pink);
}

.nextBtn,
.lastButton {
  width: 380px;
  height: 70px;
  align-self: flex-end;
  background: var(--main-gradient);
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: var(--white);
  border: none;
  border-radius: 10px;
  margin-bottom: 23px;
}

.nextBtn:hover,
.lastButton:hover {
  background: var(--main-gradient-hover);
}

.nextBtn:focus,
.lastButton:focus {
  background: var(--main-gradient-focus);
}

.nextBtn:disabled,
.lastButton:disabled {
  background: var(--grey);
  cursor: initial;
}

.registrationAgreementWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-self: flex-end;
}

.customCheckboxWrapper {
  display: flex;
}

.policyAgreementText {
  padding-top: 6px;
  max-width: 300px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 130%;
  color: var(--purple);
}

.registrationPolicyWrapper {
  display: flex;
  gap: 5px;
}

.customCheckboxLabel {
  padding-left: 17px;
  position: relative;
  width: 100%;
  padding-top: 6px;
  max-width: 315px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 130%;
  color: var(--purple);
  cursor: pointer;
}

.customCheckboxInput + .customCheckboxLabel::before {
  position: absolute;
  content: '';
  top: 5px;
  left: 0;
  width: 12px;
  height: 12px;
  border-radius: 2px;
  background-color: var(--active-tab);
}

.customCheckboxInput:checked + .customCheckboxLabel::after {
  position: absolute;
  content: '';
  width: 7px;
  height: 8px;
  top: 7px;
  left: 2px;
  background: url('../../../../../assets/svg/customCheckboxMark.svg');
  z-index: 10;
}

.customCheckboxInput {
  display: none;
}

.active {
  cursor: pointer;
}

.inactive {
  cursor: default;
}

.contactWrapper {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
}

.nextBtnWrapper {
  padding-top: 22px;
}

.inputsError {
  padding-top: 5px;
  padding-left: 15px;
  font-size: 14px;
  color: red;
}

@media (max-width: 1230px) {
  .contactWrapper {
    flex-direction: column;
  }

  .registrationInputItem:last-child {
    padding-top: 15px;
  }

  .registrationInputList {
    flex-direction: column;
  }

  .registrationInput,
  .registrationInputItem,
  .registrationAgreementWrapper,
  .nextBtn {
    width: 100%;
  }

  .registrationFormUnderlay {
    padding-left: 150px;
    padding-right: 150px;
  }
}

@media (max-width: 1024px) {
  .registrationSection {
    padding: 70px 0;
  }

  .registrationTitle {
    padding-left: 22px;
    margin-bottom: 30px;
  }

  .registrationText {
    padding-left: 22px;
    margin-bottom: 67px;
    font-size: 24px;
  }

  .registrationForm {
    padding: 0 22px;
  }

  .registrationInputList {
    gap: 17px;
  }

  .inputsError {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .registrationSection {
    padding: 60px 0;
  }

  .registrationTitle {
    padding-left: 94px;
    margin-bottom: 20px;
    font-size: 5.21vw;
  }

  .registrationText {
    padding-left: 94px;
    margin-bottom: 50px;
    font-size: 3.126vw;
  }

  .registrationForm {
    padding: 0px 94px;
  }

  .registrationInput,
  .registrationInputItem,
  .registrationAgreementWrapper,
  .nextBtn {
    width: 100%;
  }

  .contactWrapper {
    gap: 0;
  }

  .nextBtnWrapper {
    padding-top: 0;
  }

  .registrationInputList {
    gap: 10px;
  }

  .registrationFormUnderlay {
    padding-left: 0;
    padding-right: 0;
  }

  .inputsError {
    font-size: 14px;
  }
}

@media (max-width: 550px) {
  .registrationSection {
    padding: 60px 0;
  }

  .registrationTitle {
    padding-left: 60px;
    margin-bottom: 20px;
    font-size: 5.21vw;
    line-height: 7vw;
  }

  .registrationText {
    padding-left: 60px;
    margin-bottom: 7vw;
    font-size: 3.126vw;
  }

  .registrationForm {
    padding: 0px 60px;
  }

  .registrationInput {
    width: 100%;
  }

  .registrationInputList {
    gap: 10px;
  }

  .registrationInputItem {
    width: 100%;
  }

  .nextBtn {
    width: 100%;
  }

  .inputsError {
    font-size: 10px;
  }
}

@media (max-width: 320px) {
  .registrationSection {
    padding: 40px 0;
  }

  .registrationTitle {
    padding-left: 15px;
    max-width: 290px;
    margin-bottom: 11px;
    font-size: 19px;
    line-height: 21px;
  }

  .registrationText {
    padding-left: 15px;
    max-width: 210px;
    margin-bottom: 17px;
    font-size: 17px;
  }

  .registrationForm {
    padding-left: 15px;
    padding-right: 25px;
  }

  .registrationTabsList {
    margin-bottom: 0px;
    gap: 3px;
  }

  .registrationTabsBtn {
    height: 33px;
    width: 33px;
  }

  .registrationLabel {
    font-size: 12px;
    line-height: 16px;
  }

  .inputsError {
    padding-top: 2px;
    padding-left: 15px;
    font-size: 11px;
  }

  .registrationInputList {
    margin-bottom: 13px;
  }

  .registrationInput {
    width: 280px;
    height: 50px;
    padding-left: 18px;
    padding-bottom: 3px;
    font-size: 9px;
    border: 1px solid var(--inactive-input-border);
  }

  .registrationInputList {
    gap: 0;
  }

  .registrationInputItem {
    height: 84px;
  }

  .nextBtn {
    width: 100%;
    height: 50px;
    font-size: 16px;
    line-height: 22px;
  }
}
