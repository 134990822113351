.profitSection {
  max-width: 1280px;
  margin: 0 auto;
  padding: 52px 0 90px 50px;
  color: var(--purple);
}

.profitTitle {
  margin-bottom: 50px;
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 45px;
}

.profitList {
  display: grid;
  grid-template-areas: 'statistics safety exchange comfort';
}

.profitItem {
  display: flex;
  flex-direction: column;
  width: 257px;
}

.subtitleWrapper {
  display: flex;
  margin-bottom: 10px;
}

.profitLogo {
  margin-right: 5px;
}

.subtitle {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 120%;
}

.profitText {
  max-width: 216px;
  margin-bottom: 14px;
  align-self: flex-end;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
}

.profitUnderline {
  width: 215px;
  height: 4px;
  align-self: flex-end;
  margin-right: 1px;
  background: var(--main-gradient);
}

.profitStatistics {
  grid-area: statistics;
  margin-right: 51px;
}

.profitSafety {
  grid-area: safety;
  margin-right: 51px;
}

.profitExchange {
  grid-area: exchange;
  margin-right: 51px;
}

.profitComfort {
  grid-area: comfort;
  margin-right: 51px;
}

.profitSafetyText,
.profitExchangeText,
.profitComfortText {
  margin-bottom: 37px;
}

@media (max-width: 1240px) {
  .profitList {
    place-content: center;
    grid-template-areas:
      'statistics safety'
      'exchange comfort';
  }

  .profitStatistics,
  .profitExchange {
    margin-right: 200px;
  }

  .profitStatistics,
  .profitSafety,
  .profitExchange {
    margin-bottom: 40px;
  }

  .profitSafetyText,
  .profitExchangeText,
  .profitComfortText {
    margin-bottom: 37px;
  }
}

@media (max-width: 1024px) {
  .profitSection {
    padding: 40px 0 90px 22px;
  }

  .subtitle {
    padding-top: 4px;
    font-size: 24px;
  }

  .profitItem {
    width: 241px;
  }

  .profitText {
    max-width: 213px;
    margin-bottom: 0px;
    align-self: center;
    padding-left: 27px;
  }

  .profitUnderline {
    width: 180px;
    height: 4px;
    align-self: center;
    margin: 0 0 0 20px;
  }

  .profitStatistics,
  .profitExchange {
    margin-right: 5vw;
  }

  .profitSafety,
  .profitComfort {
    margin-right: 0px;
  }

  .profitStatisticsText,
  .profitExchangeText {
    margin-bottom: 14px;
  }

  .profitSafetyText {
    margin-bottom: 37px;
  }

  .profitComfortText {
    margin-bottom: 14px;
  }
}

@media (max-width: 768px) {
  .profitSection {
    padding: 30px 0 80px 94px;
  }

  .profitTitle {
    max-width: 380px;
    margin-bottom: 40px;
    font-size: 5.21vw;
  }

  .profitItem {
    width: 257px;
  }

  .profitText {
    max-width: 216px;
    padding-left: 0px;
    align-self: flex-end;
  }

  .profitUnderline {
    width: 215px;
    align-self: flex-end;
    margin: 0 1px 0 0;
  }

  .profitComfortText {
    margin-bottom: 14px;
  }
}

@media (max-width: 660px) {
  .profitSection {
    padding: 30px 0 80px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .profitTitle {
    font-size: 7vw;
  }

  .profitList {
    place-content: center;
    grid-template-areas:
      'statistics'
      'safety'
      'exchange'
      'comfort';
  }
}

@media (max-width: 320px) {
  .profitSection {
    padding: 30px 0 60px 15px;
    display: block;
  }

  .profitTitle {
    max-width: 240px;
    margin-bottom: 20px;
    font-size: 19px;
    line-height: 21px;
  }

  .profitList {
    justify-content: start;
  }

  .profitItem {
    width: 261px;
  }

  .subtitleWrapper {
    margin-bottom: 0px;
  }

  .subtitle {
    align-self: center;
    padding-top: 1px;
    font-size: 17px;
  }

  .profitText {
    max-width: 220px;
    margin-bottom: 9px;
    font-size: 14px;
  }

  .profitUnderline {
    width: 200px;
    height: 2px;
    margin-right: 20px;
  }

  .profitExchangeText {
    margin-bottom: 9px;
  }

  .profitComfortText,
  .profitSafetyText {
    margin-bottom: 10px;
  }
}
