.historyTitle {
  margin-bottom: 14px;
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 29px;
  color: var(--white);
}

.historyTableBackground {
  height: 467px;
  padding: 21px 20px;
  background-color: var(--app-light-purple);
  border-radius: 10px;
}

.historyTableWrapper {
  height: 430px;
  overflow-x: hidden;
  overflow-y: auto;
}

.historyTableWrapper::-webkit-scrollbar {
  width: 3px;
}

.historyTableWrapper::-webkit-scrollbar-track {
  background: transparent;
}

.historyTableWrapper::-webkit-scrollbar-thumb {
  background: var(--inactive-input-border);
  border-radius: 1px;
}

.historyTableWrapper::-webkit-scrollbar-thumb:hover {
  background: var(--active-tab);
}

.historyTableHeading {
  padding: 0 120px 17px 0;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: var(--inactive-input-border);
}

.historyTableHeading:nth-child(2) {
  padding-left: 19px;
  padding-right: 120px;
}

.historyTableCell {
  position: relative;
  padding: 15px 0 9px 0;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: var(--white);
}

.historyTableCellAcc {
  width: 90px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.historyTableCellAcc .historyTableCelltooltiptext {
  position: absolute;
  padding: 6px;
  border-radius: 7px;
  top: 0;
  left: -2px;
  z-index: 1;
  background-color: var(--purple);
  visibility: hidden;
}

.historyTableCellAcc:hover .historyTableCelltooltiptext {
  visibility: visible;
}

.expenses {
  color: var(--pink);
}

.income {
  color: lightgreen;
  text-shadow:       
  0 0 3px lightgreen,
  0 0 7px lightgreen,
  0 0 10px lightgreen;
}

.historyTableCell:nth-child(2n) {
  padding-left: 20px;
}

.historyTableCell:nth-child(3n) {
  width: fit-content;
  padding-left: 12px;
}

.historyTableThead {
  border-bottom: 1px solid var(--purple);
}

.historyTable {
  width: 100%;
  border: none;
  border-collapse: collapse;
}

.historyTableHeading,
.historyTableCell {
  border-left: 1px solid var(--purple);
  border-right: 1px solid var(--purple);
}

.historyTableHeading:first-child,
.historyTableCell:first-child {
  border-left: none;
}

.historyTableHeading:last-child,
.historyTableCell:last-child {
  border-right: none;
}

@media(max-width: 870px) {
  .historyTitle {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media(max-width: 440px) {
  .historyTitle {
    font-size: 23px;
  }

  .historyTableHeading {
    padding: 0 110px 17px 0;
  }
  
  .historyTableHeading:nth-child(2) {
    padding-right: 50px;
  }
}
