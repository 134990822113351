@import '~normalize.css';

@font-face {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('assets/fonts/comfortaa/Comfortaa-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('assets/fonts/nunito/Nunito-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('assets/fonts/nunito/Nunito-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  src: local(''), url('assets/fonts/nunito/Nunito-SemiBold.ttf') format('truetype');
}

:root {
  --main-gradient: linear-gradient(87.4deg, #2e00b1 -11.74%, #8100bd 48.81%, #a400be 113.27%);
  --main-gradient-hover: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    linear-gradient(87.4deg, #2e00b1 -11.74%, #8100bd 48.81%, #a400be 113.27%);
  --main-gradient-focus: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
    linear-gradient(87.4deg, #2e00b1 -11.74%, #8100bd 48.81%, #a400be 113.27%);
  --button-hover-gradient: linear-gradient(88.2deg, #a400be -14.85%, #8100bd 50.2%, #2e00b1 115.25%, #2e00b1 115.25%);
  --button-focus-gradient: linear-gradient(88.2deg, #2e00b1 -14.85%, #a400be 50.2%, #2e00b1 115.25%, #2e00b1 115.25%);
  --white: #ffffff;
  --blue: #2e00b1;
  --grey: #b0b0b0;
  --light-grey: #dfd4eb;
  --pink: #b865d6;
  --purple: #210b36;
  --light-purple: #5f536c;
  --app-light-purple: #392350;
  --active-tab: #6e479e;
  --registration-background: #eee2fa;
  --inactive-input-border: #c6b6d7;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  height: 100%;
  width: 100%;
  position: relative;
  min-width: 320px;
  min-height: 100vh;
  font-family: Comfortaa, Nunito, sans-serif;
  font-size: 16px;
  font-weight: 400;
  overflow-x: hidden;
  background-color: var(--white);
}

img,
svg {
  max-width: 100%;
  height: auto;
}

button {
  padding: 0;
  cursor: pointer;
}

button:focus {
  outline: none;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
p,
figure,
fieldset,
blockquote {
  margin: 0;
}

iframe {
  border: none;
}

.visually-hidden {
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}
