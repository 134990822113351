.wrapper {
  --tab-color: #c6b6d7;
  --overline-width: 0;
  --line-height: 2px;
  --transition: width 0s;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  isolation: isolate;
  color: white;
  user-select: none;
}

.tabsWrapper {
  width: 600px;
  display: flex;
  justify-content: space-between;
}

.stageWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.numbers {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--tab-color);
  border-radius: 50%;
  border: none;
  outline: none;
  width: 50px;
  height: 50px;
}

.line {
  position: absolute;
  top: 29%;
  left: 71%;
  background-color: #c6b6d7;
  height: 3px;
  width: 185%;
  z-index: -2;
}

.overline {
  position: absolute;
  top: 29%;
  left: 71%;
  background-color: var(--tab-color);
  height: 3px;
  width: var(--overline-width);
  transition: var(--transition);
  z-index: -1;
}

.numbers::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  height: 65px;
  width: 65px;
  border-radius: 50%;
  border: 2px solid var(--tab-color);
}

.text {
  font-size: 13px;
  white-space: nowrap;
}

.active {
  --tab-color: #1ed637;
  --line-height: 5px;
  --overline-width: 185%;
  --transition: width 1s;
}

@media (max-width: 768px) {
  .tabsWrapper {
    width: 375px;
  }

  .line {
    width: 85%;
  }

  .active {
    --overline-width: 85%;
  }
}
