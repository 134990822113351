.accountWrapper {
  padding: 70px 40px 40px 40px;
  max-width: 1180px;
  margin: 0 auto;
}

.accountTitleWrapper {
  margin-bottom: 70px;
  display: flex;
  justify-content: space-between;
}

.accountTitle {
  padding-top: 10px;
  text-transform: capitalize;
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 45px;
  color: var(--white);
}

.accountBtn,
.withdrawCashbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 241px;
  height: 49px;
  background: var(--main-gradient);
  border: none;
  border-radius: 8px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: var(--white);
}

.accountBtn:hover,
.withdrawCashbtn:hover {
  cursor: url('../../../../../assets/svg/customHoverCursor.svg'), pointer;
  background: var(--button-hover-gradient);
}

.accountBtn:focus,
.withdrawCashbtn:focus {
  background: var(--button-focus-gradient);
}

.accountBtn:active,
.withdrawCashbtn:active {
  background: var(--blue);
}

.accountSortText {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: var(--inactive-input-border);
}

.accountSort {
  align-self: flex-start;
  padding-top: 2px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 100%;
  background: transparent;
  border: none;
  color: var(--white);
}

.accountItem {
  width: 380px;
  height: 240px;
  padding-top: 14px;
  padding-left: 18px;
  padding-right: 18px;
  background: var(--app-light-purple);
  border: 2px solid transparent;
  border-radius: 10px;
}

.accountItem:hover {
  cursor: url('../../../../../assets/svg/customHoverCursor.svg'), pointer;
  border: 2px solid var(--pink);
}

.accountNumber {
  margin-bottom: 15px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: var(--inactive-input-border);
}

.accountAmountWrapper {
  margin-bottom: 24px;
}

.accountAmount {
  margin-bottom: 5px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: var(--white);
}

.accountBTCNumber {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: var(--white);
}

.accountInfo {
  display: flex;
  justify-content: space-between;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: var(--inactive-input-border);
}

.accountInfoTextWrapper {
  display: flex;
  flex-direction: column;
}

.accountSortWrapper {
  position: relative;
}

.currencyExchangeCustomSelect {
  padding-left: 10px;
  display: flex;
  align-items: center;
  position: relative;
  height: 37px;
  width: 160px;
  background-color: var(--white);
  border-radius: 6px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 100%;
  color: var(--purple);
  cursor: pointer;
}

.currencyExchangeCustomSelect::after {
  position: absolute;
  width: 9px;
  height: 8px;
  content: '';
  top: 16px;
  right: 16px;
  background-color: transparent;
  background-repeat: no-repeat;
}

.accountSortSelectList {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 20px;
  background-color: var(--white);
  border-radius: 6px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 100%;
  color: var(--purple);
  cursor: pointer;
}

.closed {
  display: none;
}

.opened {
  display: flex;
}

.accountSortSelectItem {
  padding-left: 10px;
  padding-top: 8px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  position: relative;
}

.accountSortSelectItem:hover {
  background-color: var(--pink);
  color: var(--white);
}

.accountSortSelectItem::after {
  width: 100%;
  height: 2px;
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  background: var(--purple);
}

.preloaderWrapper {
  height: calc(100vh - 207px);
}

.activeSelect {
  transform: rotate(180deg);
}

.btnWrapper {
  display: flex;
  flex-direction: column;
}

.accountBtn {
  margin-bottom: 10px;
}

.accountInfoTitle {
  margin-bottom: 20px;
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 29px;
  color: var(--white);
}

.userInfoGraphic {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 870px) {
  .accountTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .userInfoGraphic {
    flex-direction: column;
    align-items: center;
  }

  .accountSection {
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .accountWrapper {
    padding: 50px 40px 40px 40px;
  }

  .accountTitleWrapper {
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 20px;
  }

  .btnWrapper {
    align-items: center;
    margin-bottom: 15px;
  }

  .accountSection {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .accountInfoTitle {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 440px) {
  .accountTitle {
    font-size: 30px;
  }

  .accountInfoTitle {
    font-size: 25px;
  }

  .accountItem {
    width: 300px;
    height: 220px;
  }

  .accountAmountWrapper {
    margin-bottom: 24px;
  }

  .accountBTCNumber {
    font-size: 11px;
  }

  .accountAmount {
    font-size: 28px;
  }

  .accountNumber {
    margin-bottom: 7px;
  }
}
