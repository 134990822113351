.paymentSection {
  width: 100%;
  user-select: none;
}

.paymentHeading {
  text-align: center;
  font-size: 30px;
  margin-bottom: 40px;
  color: var(--light-grey);
}

.paymentWrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.paymentVariant {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 200px;
  padding: 14px 18px;
  background: rgb(110, 71, 158, 0.8);
  transition: all 0.2s ease;
  border: 1px solid transparent;
  border-radius: 10px;
  cursor: pointer;
}

.paymentVariant:hover {
  background: rgb(80, 38, 131);
  border: 1px solid var(--white);
  box-shadow: 0 0 10px var(--white);
  transform: scale(1.05);
  cursor: url('../../../../../../assets/svg/customHoverCursor.svg'), pointer;
}

.paymentVariant:disabled {
  background: var(--light-grey);
  border: 1px solid var(--white);
  box-shadow: 0 0 10px var(--white);
  cursor: not-allowed;
  transform: scale(1);
}

.paymentVariant:disabled:hover {
  cursor: not-allowed;
}

.paymentVariantLogo {
  width: 100px;
  height: 100px;
}

.paymentVariantTitle {
  white-space: nowrap;
  color: var(--white);
}

.modalContentWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 15px;
}

.modalContentHeading {
  font-size: 30px;
  color: var(--white);
  text-align: center;
  line-height: 40px;
}

.modalContentInput {
  width: 280px;
  height: 50px;
  padding-left: 18px;
  padding-right: 80px;
  background: var(--white);
  font-size: 23px;
  border: 2px solid var(--inactive-input-border);
  border-radius: 10px;
  caret-color: var(--purple);
}

.modalContentInput:focus {
  outline: none;
  border: 2px solid var(--pink);
}

.modalContentInputWrapper {
  position: relative;
}

.modalContentInputWrapper::after {
  content: attr(data-content);
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  color: var(--inactive-input-border);
  font-size: 23px;
  font-weight: 700;
  pointer-events: none;
}

@media (max-width: 1200px) {
  .paymentWrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    width: fit-content;
    margin: 0 auto;
  }

  .paymentVariant {
    width: 250px;
  }
}

@media (max-width: 768px) {
  .paymentVariant {
    width: 200px;
  }

  .modalContentWrapper {
    padding: 30px 40px 40px 40px
  }
}

