.header {
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 25px;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 51px;
}

.logoWrapper {
  display: flex;
  max-height: 38px;
  align-items: center;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  cursor: pointer;
}

.logo {
  margin-right: 10px;
}

.logoName {
  color: var(--purple);
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
}

@media (max-width: 1024px) {
  .headerWrapper {
    padding-left: 22px;
    padding-right: 23px;
  }
}

@media (max-width: 768px) {
  .headerWrapper {
    padding: 0 94px;
  }
}

@media (max-width: 550px) {
  .headerWrapper {
    padding: 0 50px;
  }
}

@media (max-width: 320px) {
  .header {
    padding-bottom: 19px;
  }

  .headerWrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
}
