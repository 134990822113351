.contactsWrapper {
  max-width: 264px;
  margin-right: 1.15vw;
}

.contactsList {
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  margin-bottom: 19px;
  color: var(--white);
  font-family: 'Nunito';
  font-weight: 700;
  font-size: 18px;
  line-height: 130%;
}

.contactsItem:not(:last-child) {
  margin-bottom: 14.5px;
}

.contactsSocialList {
  display: flex;
}

.contactsSocialItem:first-child {
  margin-right: 18px;
}

.contactsSocial:hover path {
  fill: var(--pink);
}

@media (max-width: 1024px) {
  .contactsWrapper {
    width: 181px;
    margin-right: 2vw;
  }

  .contactsStreet {
    white-space: nowrap;
  }
}

@media (max-width: 768px) {
  .contactsWrapper {
    width: 340px;
    max-width: none;
    margin-right: 0vw;
  }

  .contactsList {
    padding-top: 68px;
    margin-bottom: 19px;
  }
}

@media (max-width: 640px) {
  .contactsWrapper {
    width: 100%;
    margin-right: 0vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .contactsList {
    width: 253px;
    padding-top: 0;
  }
}

@media (max-width: 320px) {
  .contactsWrapper {
    width: 100%;
    margin-bottom: 12px;
  }

  .contactsList {
    padding-top: 0;
    margin-bottom: 19px;
    font-size: 14px;
  }

  .contactsItem:not(:last-child) {
    margin-bottom: 8px;
  }
}
