.preloaderOverlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba($color: #000000, $alpha: 0.3);
  z-index: 10;
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wrapper {
  width: fit-content;
  position: relative;
  transform: translate(-50px);
}
