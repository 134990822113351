.registrationSuccessLink {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 380px;
  height: 70px;
  background: var(--main-gradient);
  color: var(--white);
  border-radius: 10px;
  border: none;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  cursor: pointer;
}

.registrationSuccessLink:hover {
  background: var(--main-gradient-hover);
  color: var(--white);
}

.registrationSuccessLink:focus {
  background: var(--main-gradient-focus);
  color: var(--white);
}

.registrationSuccessLink:disabled {
  background: var(--grey);
  color: var(--white);
  cursor: not-allowed;
}

.registrationSuccess {
  width: 100%;
  padding-top: 43px;
}

.registrationSuccessWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  background-color: var(--white);
  border-radius: 10px;
}

.registrationSuccessCheck {
  margin-bottom: 24px;
}

.registrationSuccessTextWrapper {
  max-width: 600px;
}

.registrationSuccessTitle {
  margin-bottom: 21px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 120%;
  text-align: center;
  color: var(--purple);
}

.registrationSuccessText {
  display: flex;
  justify-content: center;
  align-items: center;
}

.registrationSuccessBtn {
  margin-top: 30px;
}

@media (max-width: 768px) {
  .registrationSuccessLink {
    width: 49.5vw;
    font-size: 2.35vw;
  }
}

@media (max-width: 550px) {
  .registrationSuccessLink {
    width: 56.5vw;
    font-size: 3vw;
  }
}

@media (max-width: 420px) {
  .registrationSuccess {
    padding-top: 29px;
    padding-bottom: 15px;
  }

  .registrationSuccessWrapper {
    padding-top: 66px;
    padding-bottom: 85px;
  }

  .registrationSuccessTitle {
    margin-bottom: 30px;
    font-size: 17px;
  }

  .registrationSuccessText {
    font-size: 14px;
  }

  .registrationSuccessCheck {
    margin-bottom: 30px;
  }
}

@media (max-width: 320px) {
  .registrationSuccessLink {
    width: 290px;
    height: 50px;
    font-size: 16px;
    line-height: 22px;
  }
}

.registrationSuccess {
  width: 100%;
  min-height: 400px;
  padding-top: 43px;
}

.registrationSuccessWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  background-color: var(--white);
  border-radius: 10px;
}

.registrationSuccessCheck {
  margin-bottom: 24px;
}

.registrationSuccessTextWrapper {
  max-width: 600px;
}

.registrationSuccessTitle {
  margin-bottom: 21px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 120%;
  text-align: center;
  color: var(--purple);
}

.registrationSuccessText {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  color: var(--purple);
  text-align: center;
}

@media (max-width: 420px) {
  .registrationSuccess {
    padding-top: 29px;
    padding-bottom: 15px;
  }

  .registrationSuccessWrapper {
    padding-top: 66px;
    padding-bottom: 85px;
  }

  .registrationSuccessTitle {
    margin-bottom: 30px;
    font-size: 17px;
  }

  .registrationSuccessText {
    font-size: 14px;
  }

  .registrationSuccessCheck {
    margin-bottom: 30px;
  }
}

