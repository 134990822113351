.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s ease;
}

.modalContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
  width: 50vw;
  height: 80vh;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}

.handleWrapper {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  opacity: 0;
  animation: textAppearance 2s ease forwards;
}

@keyframes textAppearance {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.modalText {
  padding: 25px 40px 25px 25px;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.closeButtonLineOne {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 30px;
  height: 2px;
  background-color: black;
  border: none;
  cursor: pointer;
}

.closeButtonLineTwo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 30px;
  height: 2px;
  background-color: black;
  border: none;
  cursor: pointer;
}

.modalContentBlurUnderline {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 96%;
  height: 100px;
  background: linear-gradient(to top, rgb(255, 255, 255), rgba(255, 255, 255, 0));
  border: none;
  pointer-events: none;
}

.modalContentBlurUpperline {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 96%;
  height: 35px;
  background: linear-gradient(to bottom, rgb(255, 255, 255), rgba(255, 255, 255, 0));
  border: none;
  pointer-events: none;
}

@media (max-width: 1200px) {
  .modalContent {
    width: 75vw;
  }
}

@media (max-width: 500px) {
  .modalContent {
    width: 100vw;
  }
}
