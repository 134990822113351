.footer {
  background: var(--main-gradient);
}

.footerInfo {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 30px;
}

.footerListsWrapper {
  display: flex;
}

.footerWrapper {
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 70px;
  padding-left: 50px;
  padding-bottom: 54px;
}

.copyrights {
  width: 100%;
}

.copyrightText {
  max-width: 700px;
  color: var(--white);
  font-family: 'Nunito';
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
}

.footerLinksWrapper {
  display: flex;
  margin-right: 30px;
}

@media(max-width: 1220px) {
  .footerListsWrapper {
    width: 100%;
    margin-bottom: 25px;
    justify-content: center;
  }

  .copyrights {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .footerWrapper {
    padding-left: 22px;
    padding-bottom: 55px;
    justify-content: start;
  }

  .footerInfo {
    margin-bottom: 7px;
  }
}

@media (max-width: 768px) {
  .footerWrapper {
    padding: 60px 94px 26px 94px;
  }

  .footerInfo {
    margin-bottom: 7px;
  }

  .footerLinksWrapper {
    margin-bottom: 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
  }
}

@media (max-width: 640px) {
  .footerListsWrapper {
    flex-direction: column;
    justify-content: center;
  }

  .footerLinksWrapper {
    margin-bottom: 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 0;
  }
}

@media (max-width: 550px) {
  .footerWrapper {
    padding-left: 15vw;
  }
}

@media (max-width: 320px) {
  .footerListsWrapper {
    margin-bottom: 0;
  }

  .footerWrapper {
    padding: 40px 0 11px 15px;
  }

  .footerLinksWrapper {
    margin: 0 0 7px 0;
  }

  .footerInfo {
    margin-bottom: 34px;
  }

  .copyrights {
    font-size: 14px;
  }
}