.heading {
  text-align: center;
  font-size: 40px;
  color: var(--white);
  margin-bottom: 15px;
}

.text {
  text-align: center;
  font-size: 25px;
  color: var(--light-grey);
  line-height: 35px;
}
